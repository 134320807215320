exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guidelines-design-principles-js": () => import("./../../../src/pages/guidelines/design-principles.js" /* webpackChunkName: "component---src-pages-guidelines-design-principles-js" */),
  "component---src-pages-guidelines-overview-js": () => import("./../../../src/pages/guidelines/overview.js" /* webpackChunkName: "component---src-pages-guidelines-overview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styles-color-js": () => import("./../../../src/pages/styles/color.js" /* webpackChunkName: "component---src-pages-styles-color-js" */),
  "component---src-pages-styles-design-tokens-js": () => import("./../../../src/pages/styles/design-tokens.js" /* webpackChunkName: "component---src-pages-styles-design-tokens-js" */),
  "component---src-pages-styles-overview-js": () => import("./../../../src/pages/styles/overview.js" /* webpackChunkName: "component---src-pages-styles-overview-js" */)
}

